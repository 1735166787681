<modal-cover [title]="'YOUR YEAR AT A GLANCE'">
    <div class="wrapped-header-coverpage">
        <div class="wrapper-header d-flex align-items-center justify-content-between">
            <!-- <img src="./assets/imgages/wrapped-logo-white.png" alt="" srcset=""> -->
            <img class="white-logo" src="./assets/images/wrapped-logo-white.png" alt="" srcset="" />
            <img src="./assets/images/pink-emblem.png" alt="" srcset="" />
        </div>

        <div class="container">
            <div class="row p-4">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h1 class="text-white text-uppercase">NotchHR <span>Rewind</span></h1>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/wrapped-cover-img.png" alt="" srcset="" />
                    </div>
                </div>

                <!-- Introduction -->
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h3 class="text-white">
                            Your {{ wrapData.year }} Year in<br />
                            <span class="colored-span">Review with NotchHR</span>
                        </h3>
                        <p class="text-white mt-2 nrwrap-text">
                            Hello 👋 Partner, <br />
                            As the curtain falls on {{ wrapData.year }}, it’s time to look back at
                            the year your team thrived, excelled, and made every moment count.
                            <br /><br />
                            Here’s your company’s unique journey, as seen through the NotchHR lens.
                            🧐
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/slide-2-wrap.png" alt="" srcset="" />
                    </div>
                </div>

                <!-- End Introduction -->

                <!-- Leave Management Wrap -->
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h3 class="text-white">
                            Leave <span class="colored-span">Management</span>
                        </h3>
                        <p class="text-white mt-4 nrwrap-text">
                            • <span class="colored-span">{{ wrapData?.year }}</span> saw your team
                            embrace the importance of rest and rejuvenation: <br /><br />
                            •<span class="colored-span">{{
                                wrapData?.leaveModule?.totalLeaveDays || 0
                            }}</span>
                            leave days were taken, ensuring work-life balance across the board.
                            <br /><br />
                            •The most popular leave type was
                            <span class="colored-span">{{
                                wrapData?.leaveModule?.leaveTypeName || '*******'
                            }}</span
                            >, with
                            <span class="colored-span"
                                >{{ wrapData?.leaveModule?.percentageApprovedEmployees || 0 }}%
                            </span>
                            of employees making the most of their vacation ✈ days. <br /><br />
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/slide-3-wrap.png" alt="" srcset="" />
                    </div>
                </div>
                <!-- End Leave Management Wrap -->
            </div>
        </div>
    </div>

    <div class="wrapped-header-coverpage">
        <div class="container">
            <div class="row p-4">
                <!-- Payment Wrap -->

                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h3 class="text-white">
                            Payroll & <span class="colored-span">Payments</span>
                        </h3>
                        <p class="text-white mt-4 nrwrap-text">
                            Efficiency was the name of the game in {{ wrapData?.year }}:
                            <br /><br />

                            •You processed
                            <span class="colored-span">
                                {{ wrapData?.payrollModule?.totalPayrolls || 0 }} Payroll Cycles </span
                            >🕕 seamlessly with NotchHR. <br /><br />

                            •Payments reached employees in record time, saving you an average of
                            <span class="colored-span">
                                {{ wrapData?.payrollModule?.timePaid || 0 }}
                            </span>
                            per payroll cycle 🕗. <br /><br />

                            •With bonuses, incentives, and salaries combined, you managed over
                            <span class="colored-span">
                                {{ wrapData?.payrollModule?.formattedTotalPaidAmount || 0 }}
                            </span>
                            in payouts without a hitch. 😎
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/slide-5-wrap.png" alt="" srcset="" />
                    </div>
                </div>
                <!-- End Payment Wrap -->

                <!-- Employee  Wrap -->
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h3 class="text-white">Employee <span class="colored-span">Count</span></h3>
                        <p class="text-white mt-4 nrwrap-text">
                            Your workforce evolved significantly in this year:
                            <br /><br />

                            •
                            <span class="colored-span">
                                {{ wrapData?.employeeModule?.newEmployeesCount || 0 }} new employees
                            </span>
                            joined the team, bringing fresh perspectives and energy⚡. <br /><br />

                            •<span class="colored-span">
                                {{
                                    wrapData?.employeeModule?.terminatedEmployeesCount || 0
                                }}
                                employees</span
                            >
                            bid farewell, moving 🚶‍♂️ on to new opportunities. <br /><br />

                            •By year-end, your total employee count stood strong 💪 at
                            <span class="colored-span">
                                {{ wrapData?.employeeModule?.activeEmployeesCount }}
                            </span>
                            — a
                            <span class="colored-span">
                                {{ wrapData?.employeeModule?.percentageIncrease || 0 }} %</span
                            >
                            increase from last year.
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/slide-6-wrap.png" alt="" srcset="" />
                    </div>
                </div>

                <!-- End Employee  Wrap -->

                <!-- E-learning Wrap -->
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h3 class="text-white">E-<span class="colored-span">Learning</span></h3>
                        <p class="text-white mt-4 nrwrap-text">
                            Upskilling took center stage in {{ wrapData?.year }}: <br /><br />

                            •Your team completed
                            <span class="colored-span"
                                >{{
                                    timeFormatter(wrapData?.elearningModule?.totalTime, 'H')
                                }}
                                hours of training</span
                            >, equivalent to
                            <span class="colored-span"
                                >{{
                                    timeFormatter(wrapData?.elearningModule?.totalTime, 'D')
                                }}
                                days</span
                            >
                            of continuous learning! <br /><br />

                            •The most popular course?
                            <span class="colored-span"
                                >"{{
                                    wrapData?.elearningModule?.mostEnrolledCourse?.name || '***'
                                }}"</span
                            >
                            completed by
                            <span class="colored-span">{{
                                wrapData?.elearningModule?.mostCompletions
                            }}</span>
                            member(s) of your workforce. <br /><br />

                            •Congratulations!
                            <span class="colored-span">{{
                                wrapData?.elearningModule?.mostEnrolledCourse?.name || '***'
                            }}</span>
                            had
                            <span class="colored-span"
                                >{{
                                    wrapData?.elearningModule?.totalEnrollments || 0
                                }}
                                enrollments</span
                            >
                            from your team this year.
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/slide-7-wrap.png" alt="" srcset="" />
                    </div>
                </div>
                <!--End E-learning Wrap -->
            </div>
        </div>
    </div>

    <div class="wrapped-header-coverpage">
        <div class="container">
            <div class="row p-4">
                <!-- Perrforance Wrap -->

                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h3 class="text-white">Perfor<span class="colored-span">mance</span></h3>
                        <p class="text-white mt-4 nrwrap-text">
                            Your team’s dedication to excellence shone through:
                            <br /><br />

                            •<span class="colored-span"
                                >{{
                                    wrapData?.performanceModule?.companyCompletedGoalInPercentage ||
                                        0
                                }}%</span
                            >
                            of company-wide goals were achieved 🏆 or exceeded this year.
                            <br /><br />

                            <span class="colored-span">{{
                                wrapData?.performanceModule?.companyCompletedGoals || 0
                            }}</span>
                            performance reviews were conducted, fostering transparency and growth
                            🚀. <br /><br />

                            •Star stat: The
                            <span class="colored-span">{{
                                wrapData?.performanceModule?.departmentWithHighestRank?.name ||
                                    '***'
                            }}</span>
                            led the way, hitting 🎯
                            <span class="colored-span"
                                >{{
                                    wrapData?.performanceModule?.departmentWithHighestRank
                                        ?.percentage || '***'
                                }}%</span
                            >
                            of their OKRs.
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/slide-5-wrap.png" alt="" srcset="" />
                    </div>
                </div>
                <!-- End Performance Wrap -->

                <!-- Attendance  Wrap -->
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h3 class="text-white">Atten<span class="colored-span">dance</span></h3>
                        <p class="text-white mt-4 nrwrap-text">
                            Your team showed remarkable commitment this year:
                            <br /><br />

                            •The
                            <span class="colored-span">
                                {{
                                    wrapData?.attendanceModule?.statistics?.topDepartment
                                        ?.departmentName || '***'
                                }}
                            </span>
                            claimed the title of “Most Punctual,” with an attendance score of
                            <span class="colored-span"
                                >{{
                                    wrapData?.attendanceModule?.statistics?.topDepartment
                                        ?.timeInCount || 0
                                }}%</span
                            >
                            (take a bow 🤗,
                            {{
                                wrapData?.attendanceModule?.statistics?.topDepartment
                                    ?.departmentName || '***'
                            }}!). <br /><br />

                            •The busiest day?
                            <span class="colored-span">
                                {{
                                    wrapData?.attendanceModule?.statistics?.topAttendanceDate
                                        ?.date || '***'
                                }}</span
                            >, when most of employees showed up to tackle a major product launch.
                            <br /><br />
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/slide-3-wrap.png" alt="" srcset="" />
                    </div>
                </div>

                <!-- End Attendance  Wrap -->

                <!-- Conclusion Wrap -->
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-left">
                        <h3 class="text-white">
                            Looking Ahead<span class="colored-span">
                                to {{ wrapData.year + 1 }}</span
                            >
                        </h3>
                        <p class="text-white mt-4 nrwrap-text">
                            From onboarding new talent to fostering growth through learning and
                            development, your
                            <span class="colored-span"> {{ wrapData.year }}</span> was nothing short
                            of inspiring. As we gear up for
                            <span class="colored-span"> {{ wrapData.year + 1 }}</span
                            >, let’s aim for even greater milestones together. 💪 <br /><br />

                            Here's to your continued success ✨, powered by
                            <span class="colored-span"> NotchHR</span>! <br /><br />

                            Warm regards, 🙏 The <span class="colored-span"> NotchHR</span> Team
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="wrapped-right">
                        <img src="./assets/images/final-2.png" alt="" srcset="" />
                    </div>
                </div>
                <!--End Conclusion Wrap -->
            </div>
        </div>
    </div>
</modal-cover>
