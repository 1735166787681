<form class="w-100">
    <div class="form-group w-100">
        <div class="app-ng-autocomplete ml-1">
            <ng-select
                [placeholder]="'Select Employee'"
                bindLabel="name"
                class="ng-input"
                [items]="items"
                [hideSelected]="false"
                appendTo="body"
                (change)="onSelect.emit($event); myControl.reset()"
                [formControl]="myControl"
                [clearable]="false"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <app-e-drop-ui [item]="item"></app-e-drop-ui>
                </ng-template>
            </ng-select>
        </div>
    </div>
</form>
