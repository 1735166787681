<div class="row">
    <div class="d-flex flex-column w-100">
        <div class="w-100" *ngFor="let item of data; let i = index">
            <div
                style="
                    display: grid !important;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                "
                class=""
            >
                <div class="m-2" *ngFor="let tics of item">
                    <div class="media justify-content-center align-items-center">
                        <div class="avatar mr-2" [ngClass]="tics.avatarBackground">
                            <div class="avatar-content">
                                <i [data-feather]="tics.icon" class="avatar-icon"></i>
                            </div>
                        </div>
                        <div class="media-body my-auto">
                            <h4 class="mb-0 value" [ngStyle]="tics.style?.title">
                                {{ tics.title }}
                            </h4>
                            <p
                                [ngbTooltip]="tics.description"
                                [ngStyle]="tics?.style?.description"
                                style="max-width: 200px"
                                class="text-truncate desc card-text font-small-2 mb-0"
                            >
                                @if (tics.template) {
                                    <ng-container
                                        *ngTemplateOutlet="
                                            tics.template;
                                            context: { data: tics.templateData }
                                        "
                                    ></ng-container>
                                } @else {
                                    {{ tics.description }}
                                }
                            </p>
                        </div>
                    </div>
                </div>
                <div class="test m-2" *ngFor="let list of list[i]">
                    <div class="d-flex flex-column">
                        <div
                            [ngStyle]="{ 'font-size': list.font || DEFAULT_FONT }"
                            class="font-weight-bolder listHeader mb-0"
                        >
                            {{ list.title }}
                        </div>
                        <hr style="margin: 0 !important ; width: 100%" />
                        <div
                            *ngFor="let item of list.list"
                            [ngStyle]="{ 'font-size': list.font || DEFAULT_FONT }"
                            class="d-flex w-100 justify-content-between listHeader-body"
                        >
                            <span
                                placement="top"
                                [ngbTooltip]="item.title"
                                class="atitle text-truncate d-block"
                                >{{ item.title | titlecase }}</span
                            ><span class="font-weight-bold">{{ item.value }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="data.length - 1 !== i" />
        </div>
    </div>
</div>
