import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import endpoints from 'src/app/utils/endpoints';
import { QueryGenerator } from '../../recruitment/shared/helper';
import { UtilsService } from './utils.service';
import { AppStore } from 'src/app/app-data';
import { take } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiService extends HttpClient {
    endpoint = endpoints.MX_BE.V1.billing;
    main = endpoints.MX_BE.V1;
    endpointV2 = endpoints.MX_BE.V2.billing;
    constructor(
        handle: HttpHandler,
        private util: UtilsService,
        private appStore: AppStore
    ) {
        super(handle);
    }

    async setRolePermission(data: any) {
        return await this.put(this.endpoint.setPermission, data).toPromise();
    }

    async setPin(data: any) {
        return await this.put(this.endpoint.setPin, data).toPromise();
    }
    async getOTP() {
        return await this.get(this.endpoint.getOTP, {
            headers: {
                nospinner: 'true',
            },
        }).toPromise();
    }

    async getCompliance() {
        return await this.get(this.endpoint.getCompliance).toPromise();
    }

    async updateCompliance(data: any) {
        return await this.put(this.endpointV2.updateCompliance, data).toPromise();
    }
    async getBalance(headers?: any) {
        if (this.util.balance.getValue()) {
            return this.util.balance.getValue();
        }
        const result: any = await this.get(this.endpoint.getBalance, {
            headers,
        }).toPromise();
        this.util.balance.next(result.response);
        return result.response;
    }

    async verifyAccount(account: string, bankCode: string) {
        return await this.get(
            `${endpoints.MX_BE.V1.employees.verifyBankDetails}/${account}/${bankCode}`,
            {
                headers: {
                    nospinner: 'true',
                },
            }
        ).toPromise();
    }
    async addBeneficiary(data: any) {
        return await this.post(`${this.endpoint.createBeneficiary}`, data).toPromise();
    }
    async getBeneficiaryTransactions(recipientCode: string) {
        return await this.get(
            `${this.endpoint.getWallet}?recipientCode=${recipientCode}`
        ).toPromise();
    }
    async getBeneficiaries({ page = 1, limit = 10, ...query }: any, loader: boolean) {
        let headers: any = {};
        if (!loader) {
            headers['nospinner'] = 'true';
        }
        const result: any = await this.get(
            `${this.endpointV2.getBeneficiaries}${QueryGenerator({ page, limit, ...query })}`,
            {
                headers: {
                    ...headers,
                },
            }
        ).toPromise();
        this.util.totalBeneficiciaries$.next(result.data?.paging?.totalCount);
        return result;
    }
    async deleteBeneficiary(id: string) {
        return await this.delete(`${this.endpoint.deleteBeneficiary}/${id}`).toPromise();
    }

    async initiateTransaction(query: any) {
        return await this.get(
            `${this.endpoint.initiateTransaction}${QueryGenerator(query)}`
        ).toPromise();
    }

    async getVirtualAccounts() {
        const wallet = this.util.wallets$.getValue();
        if (wallet.length) {
            return wallet;
        }
        const user: any = await this.appStore.getUserInfo().pipe(take(1)).toPromise();
        let result: any = await this.get(`${this.endpoint.getVirtualAccounts}`).toPromise();
        this.setWallets(result.response);

        return result;
    }

    setWallets(result: any[]) {
        this.util.wallets$.next(
            result
                .filter((item: any) => {
                    return !['paystack', 'flutterwave'].includes(item.channel.toLowerCase());
                })
                .sort((item1: any, item2: any) => {
                    if (item1.channel < item2.channel) {
                        return -1;
                    }
                    if (item1.channel > item2.channel) {
                        return 1;
                    }
                    return 0;
                })
        );
    }

    async createVirtualAccounts(data: any) {
        let result: any = await this.post(`${this.endpoint.createVirtualAccounts}`, data, {
            headers: {
                // nospinner: 'true',
                //noerroralert: 'true',
            },
        }).toPromise();
        this.setWallets(result.response);
        return result.errors;
    }

    async createBilling(type: string, data: any) {
        return await this.post(`${this.endpoint.createBilling}?type=${type}`, data).toPromise();
    }

    async transfer(data: any) {
        return await this.post(`${this.endpoint.transfer}`, data).toPromise();
    }

    async getTransactions(query: any) {
        const result: any = await this.get(
            `${this.endpoint.getWallet}${QueryGenerator(query)}`
        ).toPromise();
        this.util.transactions$.next(result.response);
        return result;
    }

    async getTransaction(type: any, ref: any) {
        return await this.get(`${this.endpoint.getTransaction}/${type}/${ref}`, {
            headers: {
                noerroralert: 'true',
            },
        }).toPromise();
    }

    async reportTransaction(data: any) {
        return await this.post(`${this.endpoint.reportTransaction}`, data).toPromise();
    }

    async getAttendanceDashboard(query: any = {}) {
        return await this.get(
            `${endpoints.MX_BE.V1.attendance.dashboard}${QueryGenerator(query)}`,
            {
                headers: {
                    nospinner: 'true',
                },
            }
        ).toPromise();
    }
    getPayrollAnalytics() {
        return this.get(`${this.main.payroll.analytics}`);
    }

    getMedicalAnalytics() {
        return this.get<any>(`${this.main.medical.analytics}`);
    }

    getLeaveAnalytics() {
        return this.get<any>(`${this.main.leave.analytics}`);
    }

    getTaxAndPensionAnalytics() {
        return this.get<any>(`${this.main.taxandpension.analytics}`);
    }

    updateCompanyProfile(payload: any) {
        return this.patch<any>(`${this.main.users.updateCompanyProfile}`, payload);
    }

    getFlashNews() {
        return of([
            {
                title: 'A Must Read',
                message: `<p  style='padding:10px'>Hello there,<br />
          We are reaching out with an important update about your NotchHR licensing. Since we introduced the licensing fee in July last year, we have implemented several new features to enhance your experience, including:<br />
          <ul>
<li>Time Tracker</li>
<li> Promotions Management</li> 
<li>Enhanced Security</li>
<li>Exit Management</li>
<li>Pre-employment Testing</li>
<li>AI in Recruitment</li>
<li>....and much more!</li>

</ul>
          <p>To accommodate these improvements, we’ve made some adjustments to our licensing packages. Additionally, our free license package will now be limited to 10 employees.</p> <br />
<p><b>These changes will take effect on March 1, 2025.</b></p>
<p>

You can review the updated licensing plans by clicking on the link below.<br/>
<a target="_blank" href="https://drive.google.com/file/d/1u4xEVRzD5Oo4ChdzK9ljs5oCoyGkz4hn/view?usp=drivesdk&utm_campaign=Important+Updates+to+Your+NotchHR+Licensing&utm_medium=email&utm_source=newsletter" >NEW LICENSING PACKAGE</a>
</p>

<p>If you have any questions or need assistance, please don’t hesitate to reach out to our Customer Support Team at <b>hello@notchhr.io</b> or call <b>09137173363 or 09137173400</b>. We’re always here to help!,<br/>
     Thank you for choosing NotchHR.<p/>`,
            },
        ]);
    }

    getWrappedInfo() {
        return this.get(`${endpoints.MX_BE.V1.users.getWrappedInfo}`, {
            headers: {
                nospinner: 'true',
            },
        }).toPromise();
    }
}
