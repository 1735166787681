import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from 'src/environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { NegateAuthGuard } from './guards/negateAuth.guard';
import { IROUTES } from './interfaces/general';

import { AuthenticatedComponent } from './modules/authentication/authenticated/authenticated.component';
import { LoginComponent } from './modules/authentication/login/login.component';
import { HealthWrapperComponent } from './modules/health/health.component';
// import { DashboardModule } from './modules/dashboard/dashboard.module';
import { featureFlagGuard } from './guards/feature-flag.guard';
import { ForgotPasswordComponent } from './modules/authentication/forgot-password/forgot-password.component';
import { RegisterComponent } from './modules/authentication/register/register.component';
import { VerifyComponent } from './modules/dashboard/components/verify/verify.component';
import { ApprovalWorkflowDashboardComponent } from './modules/settings/approval-workflow/dashboard/approval-workflow-dashboard.component';
import { FeatureFlagComponent } from './shared/components/feature-flag/feature-flag.component';
import { NoLicenseComponent } from './shared/components/no-license/no-license.component';
import appConstants from './utils/constants';

const env = environment.env;
const routes: Routes = [
    {
        path: '',
        component: AuthenticatedComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.dashboard,
                },
                loadChildren: () =>
                    import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'learning-and-development',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.learning,
                },
                loadChildren: () =>
                    import('./modules/learning/learning.module').then((m) => m.LearningModule),
            },
            {
                path: 'payment',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.payments,
                },
                loadChildren: () =>
                    import('./modules/payment/payment.module').then((m) => m.PaymentModule),
            },
            {
                path: 'attendance',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.attendance,
                },
                loadChildren: () =>
                    import('./modules/attendance/attendance.module').then(
                        (m) => m.AttendanceModule
                    ),
            },
            {
                path: 'employee',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.employee,
                },
                loadChildren: () =>
                    import('./modules/employee/employee.module').then((m) => m.EmployeeModule),
            },
            {
                path: 'health',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.health,
                },
                component: HealthWrapperComponent,
                loadChildren: () =>
                    import('./modules/health/health.module').then((m) => m.HealthModule),
            },
            {
                path: 'elearning',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.eLearning,
                },
                loadChildren: () => import('./modules/lms/lms.module').then((m) => m.LmsModule),
            },
            {
                path: 'tracker',
                loadChildren: () =>
                    import('./modules/tracker/tracker.module').then((m) => m.TrackerModule),
            },
            {
                path: 'project',
                loadChildren: () =>
                    import('./modules/project/project.module').then((m) => m.ProjectModule),
            },
            {
                path: 'leave',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.leave,
                },
                loadChildren: () =>
                    import('./modules/leave/leave.module').then((m) => m.LeaveModule),
            },
            {
                path: 'confirmation',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.confirmation,
                },
                loadChildren: () =>
                    import('./modules/confirmation/confirmation.module').then(
                        (m) => m.ConfirmationModule
                    ),
            },
            {
                path: 'performance',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.performance,
                },
                loadChildren: () =>
                    import('./modules/performance/performance.module').then(
                        (m) => m.PerformanceModule
                    ),
            },
            {
                path: 'promotions',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.promotions,
                },
                loadChildren: () =>
                    import('./modules/promotions/promotions.module').then(
                        (m) => m.PromotionsModule
                    ),
            },
            {
                path: 'exits',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.exits,
                },
                prod: false,
                staging: false,
                loadChildren: () =>
                    import('./modules/exit-management/exit-mgnt.module').then(
                        (m) => m.ExitMgntModule
                    ),
            },
            {
                path: 'recruitment',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.recruitment,
                },
                loadChildren: () =>
                    import('./modules/recruitment/recruitment.module').then(
                        (m) => m.RecruitmentModule
                    ),
            },
            {
                path: 'chats',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.chats,
                },
                loadChildren: () =>
                    import('./modules/chats-v2/chats-v2.module').then((m) => m.ChatsV2Module),
            },
            {
                // DmsModule
                path: 'reports',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.reports,
                },
                loadChildren: () =>
                    import('./modules/reports/reports.module').then((m) => m.ReportsModule),
            },
            {
                path: 'requisition',
                prod: false,
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.requisition,
                },
                staging: false,
                test: false,
                loadChildren: () =>
                    import('./modules/requisition/requisition.module').then(
                        (m) => m.RequisitionModule
                    ),
            },
            {
                path: 'talent-management',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.talentManagement,
                },
                loadChildren: () =>
                    import('./modules/talent_management/talent-management.module').then(
                        (m) => m.TalentManagementModule
                    ),
            },
            {
                path: 'events',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.events,
                },
                loadChildren: () =>
                    import('./modules/events/events.module').then((m) => m.EventsModule),
            },
            {
                path: 'app-settings',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.settings,
                },
                loadChildren: () =>
                    import('./modules/settings/settings.module').then((m) => m.SettingsModule),
            },

            {
                path: 'time-tracker',
                test: false,
                prod: false,
                staging: false,
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.tracker,
                },
                loadChildren: () =>
                    import('./modules/time-tracker/time-tracker.module').then(
                        (m) => m.TimeTrackerModule
                    ),
            },
            {
                path: 'job-roles',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.jobRole,
                },
                loadChildren: () =>
                    import('./modules/jobroles/jobroles.module').then((m) => m.JobrolesModule),
            },
            {
                path: 'dms',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.dms,
                },
                loadChildren: () => import('./modules/dms/dms.module').then((m) => m.DmsModule),
            },
            {
                path: 'grievance',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.grievance,
                },
                test: true,
                loadChildren: () =>
                    import('./modules/grievance/grievance.module').then((m) => m.GrievanceModule),
            },
            {
                path: 'pending-approvals',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.pendingApproval,
                },
                component: ApprovalWorkflowDashboardComponent,
            },
            {
                path: 'safety-incident-reporting',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.safetyAndIncident,
                },
                loadChildren: () =>
                    import('./modules/Safety-Incident-Reports/safety-incident-reports.module').then(
                        (m) => m.SafetyIncidentReportsModule
                    ),
            },
            {
                path: 'employee-net-promoter-score',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.enps,
                },
                loadChildren: () => import('./modules/enps/enps.module').then((m) => m.EnpsModule),
                prod: false,
                staging: false,
            },
            {
                path: 'organogram',
                canActivate: [featureFlagGuard],
                data: {
                    featureFlag: appConstants.features.organogram,
                },
                loadChildren: () =>
                    import('./modules/organogram-chart/organogram-chart.module').then(
                        (m) => m.OrganogramChartModule
                    ),
            },
            {
                path: 'no-license',
                component: NoLicenseComponent,
            },
            {
                path: 'not-available',
                component: FeatureFlagComponent,
            },
        ] as IROUTES[],
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NegateAuthGuard],
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NegateAuthGuard],
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NegateAuthGuard],
    },
    {
        path: 'verification/:token',
        component: VerifyComponent,
        canActivate: [NegateAuthGuard],
    },
    { path: '', redirectTo: '', pathMatch: 'full' },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
