<div>
    <div class="my-2 title">{{ questionIndex + 1 }}. {{ question.question }}</div>
    <div class="question-options">
        <mat-button-toggle-group>
            <mat-button-toggle
                [value]="ratingOption"
                [ngClass]="buttonClass(i)"
                *ngFor="let ratingOption of rating; let i = index"
                [disabled]="disabled"
            >
                <i
                    class="feather-icon"
                    [data-feather]="ratingOption.emoji"
                    *ngIf="isSelected(i); else notSelected"
                ></i>
                <ng-template #notSelected>{{ 2 * i + 1 }}-{{ 2 * i + 2 }}</ng-template>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
