import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FeatureFlagService } from '../shared/services/feature-flag.service';

export const featureFlagGuard: CanActivateFn = (route, state) => {
    const flagService = inject(FeatureFlagService);
    const router = inject(Router);
    const featureFlag = route.data['featureFlag'];
    if (!flagService.loaded) {
        flagService.loadFeatureFlags().subscribe((v) => v);
        //wait for it to get loaded
        while (!flagService.loaded && !flagService.failed) {}
    }
    const isEnabled = flagService.isFeatureEnabled(featureFlag);
    if (!isEnabled) {
        router.navigate(['/not-available']);
        return false;
    }
    return true;
};
