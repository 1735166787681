import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import endpoints from 'src/app/utils/endpoints';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {
    private flags: Map<string, boolean> = new Map();
    private rolesAndPermission!: {
        MODULES: {
            [key: string]: string;
        };
        APPLICATION_ACTION_BASED_PERMISSIONS: {
            [key: string]: {
                [key: string]: string;
            };
        };
        APPLICATION_PAGE_VIEW_PERMISSIONS: {
            [key: string]: {
                [key: string]: string;
            };
        };
    };

    private license!: {
        [key: string]: {
            key: string;
            label: string;
            default: boolean;
        };
    };
    loaded: boolean = false;
    failed: boolean = false;

    constructor(private http: HttpClient) {}

    loadFeatureFlags(): Observable<void> {
        return this.http.get<any[]>(endpoints.MX_BE.V1.flags).pipe(
            map((result: any) => {
                this.failed = false;
                //handle license
                this.license = result?.licenseModules;

                //handle roles
                this.rolesAndPermission = result?.rolesAndPermission;

                //handle flags
                const flags = result.featureFlags;
                flags.forEach((flag: any) => this.flags.set(flag.name, flag.enabled));

                this.loaded = true;
            }),
            catchError((error) => {
                console.error('Failed to load feature flags', error);
                this.failed = true;
                return of(); // Handle error gracefully
            })
        );
    }

    isFeatureEnabled(name: string): boolean {
        return this.flags.get(name) ?? false;
    }

    getLicenseModules() {
        return this.license;
    }

    getLicenseModulesMap() {
        return Object.values(this.license).reduce((maps: any, current: any) => {
            maps[current.key] = current.label;
            return maps;
        }, {});
    }

    getRoles() {
        return this.rolesAndPermission;
    }
}
