import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppStore } from '../app-data';
import { DataTableConfig, filterValue } from '../interfaces/general';
import appConstants from '../utils/constants';

@Injectable({
    providedIn: 'root',
})
export class TableConfigsService {
    defaultPageOptions = [
        {
            key: '10',
            value: '10',
            selected: true,
        },
        {
            key: '20',
            value: '20',
        },
        {
            key: '50',
            value: '50',
        },
        {
            key: '100',
            value: '100',
        },
    ];
    defaultPeriodOptions = [
        {
            key: 'All',
            value: '',
            selected: true,
        },
        {
            key: 'Last 7 Days',
            value: '7',
        },
        {
            key: 'Last 14 days',
            value: '14',
        },
        {
            key: 'Last 30 days',
            value: '30',
        },
    ];

    constructor(private store: AppStore) {}

    periodTransform(value: any): filterValue {
        const date = dayjs().subtract(value, 'day').toDate().toISOString().split('T')[0];
        return {
            operator: '>=',
            value: date,
        };
    }

    getInterviewTableConfig(): DataTableConfig {
        return {
            searchOptions: [
                {
                    name: 'limit',
                    width: '10%',
                    type: 'select',
                    options: appConstants.defaultPageOptions,
                },
                {
                    name: 'recent',
                    width: '15%',
                    type: 'select',
                    options: appConstants.defaultPeriodOptions,
                },
                {
                    name: 'search',
                    width: '45%',
                    type: 'search',
                },
                {
                    name: 'type',
                    width: '15%',
                    type: 'select',
                    options: [
                        {
                            key: 'By Type',
                            value: '',
                            selected: true,
                        },
                        {
                            key: 'Virtual',
                            value: 'Virtual',
                        },
                        {
                            key: 'On-site',
                            value: 'On-site',
                        },
                    ],
                },
                {
                    name: 'date',
                    width: '15%',
                    type: 'date',
                },
            ],
            columns: [
                {
                    label: 'Interview Title',
                    key: 'title',
                },
                {
                    label: 'Job Opportunity',
                    key: 'jobOpportunity',
                    type: 'string',
                },
                {
                    label: 'Date of Interview',
                    key: 'date',
                    type: 'date',
                },
                {
                    label: 'Action',
                    key: 'action',
                    noSort: true,
                },
            ],
            exportOptions: {
                fileName: 'interview',
            },
            url: `${environment.recruitmentEndpoint}/v1/interview/active-interviews`,
        };
    }
}
