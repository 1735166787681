import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
    @Input() question!: any;
    @Input() disabled = false;
    @Input() questionIndex!: number;

    get isOdd(): boolean {
        return this.question.rating % 2 === 1;
    }
    isSelected(i: number): boolean {
        return this.question.rating === 2 * i + 1 || this.question.rating === 2 * i + 2;
    }

    buttonClass(i: number): string {
        let className = this.isSelected(i) ? `rating-${this.rating[i].color}` : '';
        className += this.isOdd ? ' odd' : '';
        return className;
    }
    rating = [
        {
            color: 'very_poor',
            emoji: 'frown',
        },
        {
            color: 'poor',
            emoji: 'meh',
        },
        {
            color: 'average',
            emoji: 'smile',
        },
        {
            color: 'good',
            emoji: 'smile',
        },
        {
            color: 'very_good',
            emoji: 'smile',
        },
    ];

    constructor() {}

    ngOnInit(): void {}
}
